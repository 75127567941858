import { defineStore } from "pinia";

export const useLocaleStore = defineStore("locale", {
  state: () => ({
    locale: null,
  }),
  actions: {
    setLocale(locale) {
      this.locale = locale;
    },
  },
  getters: {
    getLocale: (state) => state.locale,
  },
});
